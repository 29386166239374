import { render, staticRenderFns } from "./PeekServices.vue?vue&type=template&id=05bb9efe&scoped=true&"
import script from "./PeekServices.vue?vue&type=script&lang=js&"
export * from "./PeekServices.vue?vue&type=script&lang=js&"
import style0 from "./PeekServices.vue?vue&type=style&index=0&id=05bb9efe&prod&lang=css&"
import style1 from "./PeekServices.vue?vue&type=style&index=1&id=05bb9efe&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05bb9efe",
  null
  
)

export default component.exports